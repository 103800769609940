/*--- Buttons ---*/
.button-wrap{
    @include display-flex(flex);
    gap: 2rem;
    @include tablet-small{
        @include justify-content(center);
    }
}
.button{
    padding: 1.6rem 3rem;
    font-size: 1.6rem;
    font-weight: 500;
    @include border-radius(1rem);
    border: 1px solid;
    text-decoration: none;
    &--primary{
        background-color: $color-primary;
        color: $color-white;
        border-color: $color-primary;
        &:hover,&:focus{
            background-color: transparent;
            color: $color-primary;
        }
    }
    &--secondary{
        background-color: $color-white;
        color: $color-black;
        border-color: $color-white;
        &:hover,&:focus{
            background-color: transparent;
        }
    }
}

/*--- Form Elements ---*/
.form-field{
    margin-bottom: 1.6rem;
}
.form-input, input[type="text"],input[type="email"],input[type="password"],input[type="number"],input[type="url"]{
    border: none;
    background-color: $color-primaryLight;
    padding: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.28px;
    color: $color-black;
    width: 100%;
}