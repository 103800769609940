.header{
    padding-top: 3.8rem;
    padding-bottom: 3.8rem;
    @include tablet-small{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    &__navbar{
        .navbar-brand{
            font-size: 2.6rem;
            color: $color-black;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            span{
                color: $color-primary;
            }
        }
        .navbar-collapse{
            margin-left: auto;
            flex-grow: initial;
            @media (max-width:991px){
                position: absolute;
                top: 100%;
                background: $color-white;
                padding: 2rem;
                border-radius: 1rem;
                width: 100%;
                left: 0;
                margin-top: 1rem;
            }
        }
        .navbar-pages{
            border-right: 1px solid $color-black;
            @media (max-width:991px){
                border-right: none;
            }
        }
        .navbar-toggler{
            width: 4.5rem;
        }
        .navbar-nav{
            padding: 0 2.1rem;
            @media (max-width:991px){
                padding: 0;
            }
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
                @include display-flex(flex);
                @include justify-content(center);
                @media (max-width:991px){
                    @include flex-direction(column);
                }
            }
            .navbar-item{
                padding: 0 2.1rem;
                @media (max-width:991px){
                    padding: 5px 0;
                    text-align: left;
                }
                a{
                    padding: 0;
                    color: $color-black;
                    font-weight: 500;
                    font-size: 1.6rem;
                    &:hover{
                        color: $color-primary;
                    }
                }
                &:last-child{
                    padding-right: 0;
                }
            }
            &:last-child{
                padding-right: 0;
            }
        }
    }
    &__inner{
        width: 100%;
        @include display-flex(flex);
        @include justify-content(space-between);
        padding: 1.8rem 3.4rem;
        background-color: $color-white;
        @include border-radius(1rem);
        @media (max-width:991px){
            position: relative;
        }
        @include tablet-small{
            padding: 1rem 2rem;
        }
    }
}