.login-wrap{
    &__inner{
        @include display-flex(flex);
        @include justify-content(center);
        @include align-item(center);
        @include tablet-small{
            @include flex-wrap(wrap);
            @include flex-direction(column-reverse);
            padding-top: 0;
        }
        .login-form{
            width: 32.92%;
            padding: 3.6rem;
            @include border-radius(1rem);
            background-color: $color-white;
            text-align: left;
            @include tablet{
                width: 50%;
            }
            @include tablet-small{
                width: 100%;
                text-align: center;
            }
            h2{
                font-weight: 400;
                letter-spacing: 0.48px;
                margin-bottom: 1.2rem;
                color: #07122F;
                strong{
                    font-weight: 500;
                }
            }
            p{
                margin-bottom: 2.4rem;
                color: #8D93A1;
            }
            .button{
                width: 100%;
                display: block;
                margin-bottom: 1.6rem;
                text-align: left;
                position: relative;
                letter-spacing: 0.28px;
                padding: 2rem 2.4rem;
                font-size: 1.4rem;
                line-height: 1.5;
                &::after{
                    content: "";
                    position: absolute;
                    width: 5.6rem;
                    height: 100%;
                    top: 0;
                    right: 0;
                    background-position: center;
                    background-repeat: no-repeat;
                    border-radius: 0px 10px 10px 0px;
                }
                &--primary{
                    
                    &::after{
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M9 6L15 12L9 18' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                        background-color: $color-primaryDark;
                    }
                }
                &--secondary{
                    border: 1px solid #E4EBF3;
                    margin-bottom: 0;
                    &::before{
                        content: "";
                        display: inline-block;
                        width: 2.4rem;
                        height: 2.4rem;
                        border-radius: 50%;
                        background-color: $color-grayLight;
                        margin-right: 2.4rem;
                        vertical-align: middle;
                    }
                    &::after{
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M9 6L15 12L9 18' stroke='%23E27E0E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                        background-color: $color-primaryLight;
                    }
                }
            }
            .form-links{
                ul{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    li{
                        display: block;
                        color: $color-primary;
                        padding: 2.4rem 0;
                        border-bottom: 1px solid $color-grayLight;
                        @include tablet-small{
                            padding: 1.5rem 0;
                        }

                        a{
                            color: inherit;
                        }
                        &:last-child{
                            border-bottom: none;
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
        .login-content{
            width: 67.08%;
            @include display-flex(flex);
            @include justify-content(center);
            @include align-item(center);
            text-align: left;
            @include tablet{
                width: 50%;
            }
            @include tablet-small{
                width: 100%;
                text-align: center;
            }
            &-inner{
                max-width: 38.2rem;
                .brandName{
                    background-color: $color-white;
                    padding: .5rem 1.6rem;
                    @include border-radius(3rem);
                    font-size: 2.6rem;
                    color: $color-black;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    span{
                        color: $color-primary;
                    }
                }
                h1{
                    font-size: 6.4rem;
                    line-height: 1.5;
                    letter-spacing: 1.28px;
                    margin-bottom: 2.4rem;
                    @include tablet-small{
                        font-size: 3.2rem;
                        margin-bottom: 1.8rem;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}