/* ========== media mixin ========== */

  @mixin desktop-small {
    @media screen and (max-width:1439px) {
      @content;
    }
  }
  
  @mixin tablet {
    @media screen and (max-width:1279px) {
      @content;
    }
  }
  
  @mixin tablet-small {
    @media screen and (max-width:1023px) {
      @content;
    }
  }
  
  @mixin mobile {
    @media screen and (max-width:767px) {
      @content;
    }
  }
  
  @mixin mobile-small {
    @media screen and (max-width:666px) {
      @content;
    }
  }
  
  /* ========== common mixin ========== */
  
  @mixin col {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  @mixin row {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  
  @mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
  }
  
  @mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
  }
  
  @mixin transition($trans) {
    -webkit-transition: $trans;
    -moz-transition: $trans;
    transition: $trans;
  }
  
  @mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
  }
  
  @mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
  }
  
  @mixin translate ($x, $y) {
    @include transform(translate($x, $y));
  }
  
  @mixin flex-basis($flex-basis) {
    -webkit-flex-basis: ($flex-basis);
    flex-basis: ($flex-basis);
  }
  
  @mixin display-flex($flex) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: $flex;
  }
  
  @mixin align-item($align-item) {
    -webkit-box-align: $align-item;
    -moz-box-align: $align-item;
    -ms-flex-align: $align-item;
    -webkit-align-items: $align-item;
    align-items: $align-item;
  }
  
  @mixin align-self($align-self) {
    -webkit-align-self: $align-self;
    align-self: $align-self;
  }
  
  @mixin justify-content($justify-content) {
    -webkit-justify-content: $justify-content;
    justify-content: $justify-content;
  }
  
  @mixin flex-wrap($flex-wrap) {
    flex-wrap: $flex-wrap;
    -webkit-flex-wrap: $flex-wrap;
  }
  
  @mixin flex-direction($flex-direction) {
    flex-direction: $flex-direction;
    -webkit-flex-direction: $flex-direction;
  }
  