html {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    scroll-behavior: smooth;
    font-size: 62.5%;
}
body{
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    padding: 0;
    font-weight: 400;
    font-size: $base-fontSize;
    font-family: $base-font;
    color: $color-textBase;
    background: radial-gradient(333.77% 99.53% at 2.22% 33.27%, #C0AC98 0%, #C2AF9A 52.33%, #C1AE99 100%);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 2rem 0;
  padding: 0;
  font-weight: 700;
  text-decoration: none;
}

h1,.h1{
    font-size: 3rem;
    @include tablet-small{
      font-size: 2.4rem;
    }
}

h2,.h2{
    font-size: 2.4rem;
    @include tablet-small{
      font-size: 1.8rem;
    }
}

p {
    margin-bottom: 2rem;
  
    &:focus-visible {
      outline: none !important;
    }
}

img {
    max-width: 100%;
    vertical-align: middle;
}

.container{
    max-width: 113rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1130px,
  xxl: 1320px
) !default;

/* ========== COMMON CLASS ========== */

.d-none {
    display: none;
  }
  
  .d-flex {
    @include display-flex(flex);
  }
  
  .align-v-center {
    @include align-item(center);
  }
  
  .align-start {
    @include align-item(flex-start);
  }
  
  .align-end {
    @include align-item(flex-end);
  }
  
  .align-self-start {
    @include align-self(flex-start);
  }
  
  .justify-center {
    @include justify-content(center);
  }
  
  .justify-right {
    @include justify-content(flex-end);
  }
  
  .justify-space {
    @include justify-content(space-between);
  }
  
  .flex-wrap {
    @include flex-wrap(wrap);
  }
  
  .flex-direction {
    @include flex-direction(column);
  }
  
  .clearfix,
  .clearfix {
  
    &:before,
    &:after {
      display: table;
      content: " ";
    }
  
    &:after {
      clear: both;
    }
  }
  .text-left {
    text-align: left;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-right {
    text-align: right;
  }
  .color-primary{
    color: $color-primary;
  }