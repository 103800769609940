.landing-wrap{
    @include display-flex(flex);
    @include justify-content(center);
    @include align-item(center);
    padding-top: 5rem;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    @include tablet-small{
        @include flex-wrap(wrap);
        @include flex-direction(column-reverse);
        padding-top: 0;
    }
    &__left{
        width: 61.33%;
        padding: 0 2.5rem;
        text-align: left;
        @include tablet-small{
            width: 100%;
            text-align: center;
        }
        h1{
            margin-bottom: 2rem;
        }
        p{
            font-size: 2.6rem;
            margin-bottom: 4rem;
            @include tablet-small{
                font-size: 1.8rem;
            }
        }
    }
    &__right{
        width: 38.67%;
        padding: 0 2.5rem;
        @include tablet-small{
            width: 100%;
            margin-bottom: 2rem;
        }
        img{
            @include mobile{
                max-width: 200px;
            }
        }
    }
}